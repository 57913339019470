@import "../../variables";


.home-description .page-description::after {
  content: '';
  //z-index: 10;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  background: linear-gradient(261.15deg, rgba(255, 255, 255, 0) 13.53%, rgba(255, 255, 255, 0.47918) 60.02%, #FFFFFF 96.8%);


  @media (max-width: 991px) {
    //background: linear-gradient(252.14deg, rgba(255, 255, 255, 0) 26.03%, rgba(255, 255, 255, 0.679971) 47.08%, #FFFFFF 95.27%);
    background: linear-gradient(252.14deg, rgba(255, 255, 255, 0) 26.03%, rgba(255, 255, 255, 0.679971) 57.08%, #FFFFFF 95.27%);

  }

  @media screen and (max-width: 767px){
    background: transparent;
  }
}

.home-description {

  position: relative;
  background-color: #FFFFFF;

  .page-description {
    position: relative
  }

  .bg-image {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left;
    z-index: 0;
  }


  .home-description-content-container {
    position: absolute;
    top: 0;
    left: 0;
    //z-index: 3;
    width: 100%;
    height: 100%;
    padding-top: var(--milluu-header-end-h, 120px);


    display: flex;
    align-items: center;

    @media screen and (max-width: 991px) {
      padding-bottom: 50px;
      align-items: flex-end;
    }

    @media screen and (max-width: 767px) {
      position: relative;
      padding-top: 50px;
      padding-bottom: 50px;
      width: auto;
      height: auto;
      align-items: flex-start;
    }

    .home-description-content {
      max-width: 500px;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      //color: #272727;

      .description-header {
        font-size: 3rem;
        line-height: 3.1rem;
        font-weight: 400;

        letter-spacing: -0.04em;
        strong {
          font-weight: 600;
        }
        margin-bottom: 30px;

        a {
            color: #272727;
        }
      }

      .description-info {
        margin-bottom: 30px;
        font-size: 18px;
        line-height: 28px;
        max-width: 467px;
      }

      @media screen and (max-width: 767px) {
        max-width: unset;

       .description-header {
          font-size: 33px;
          line-height: 37px;
       }
       .description-info {
          max-width: unset;
       }

      }
    }
  }



}
