@import "../../variables";

.tenantsV2-component {
    --section-vertical-space: 100px;
    --section-vertical-space-inner: 50px;

    @media (max-width: 767px){
      --section-vertical-space: 50px;
    }

    background-color: #F1F4F6;
}

.black-button:hover {
    background-color: #272727;
    color: white;
}

.white-button {
    background-color: transparent;
    color: black;
}

.download-button-container {
    width: auto;
    @media (max-width: 575px){
        width: 100%;
    }
}

.download-button {
    display: flex;
    justify-content: start;
    @media (max-width: 575px){
        justify-content: center;
    }
}

.action-container {
    min-height: 200px;
    @media (max-width: 767px){
        min-height: 0px;
        width: 100%;
    }
}

.black-button {
    background-color: #272727;
    color: white;
}

.download-container {
    padding: 1rem;
    background-color: white;
    color: #272727;
}

.tenantsV2-component .wrapper-section-hero {
    padding-top: var(--milluu-header-end-h, 120px);

    background: transparent;


    .wrapper-hero-description {
      max-width: 486px;
      display: flex;
      flex-direction: column;
      margin-top: 100px;
      height: 100%;

      @media (max-width: 767px){
        margin-top: 0px;
      }

      .tenant-h1 {
        font-size: 20px;
        line-height: 49px;
        letter-spacing: -0.04em;
      }

      .tenant-h2 {
        font-size: 48px;
        font-weight: 600;
        line-height: 49px;
        letter-spacing: -0.04em;
      }

      .tenant-h3 {
        margin-top: 30px;
        margin-bottom: 30px;
        font-size: 18px;
        line-height: 22px;
      }

    }

    .wrapper-hero-img {
        padding-top: 50px;

        display: flex;
        align-items: end;
        height: 100%;
    }


    @media (min-width: 768px) and (max-width: 991px) {
      .wrapper-hero-description {
        .tenant-h2 {
          font-size: 44px;
          line-height: 45px;
        }
      }
    }


    @media  (max-width: 767px) {
       //margin-top: 30px;
      .wrapper-hero-description {
         max-width: 100%;
        .tenant-h1 {
          font-size: 18px;
          line-height: 37px;
          letter-spacing: -0.04em;
        }

        .tenant-h2 {
          font-size: 36px;
          font-weight: 600;
          line-height: 37px;
          letter-spacing: -0.04em;
          margin-top: 50px;
        }

        .tenant-h3 {
          font-size: 18px;
          line-height: 22px;
        }
      }

      .wrapper-hero-img {
        padding-top: 0rem;
        //height: 500px;
      }
    }
}

.tenantsV2-component .wrapper-tenants-news {
    background: white;
}


@media (max-width: 991px) {
  .tenants-component {
    .table-services {
      .option {
        & > img {
          padding-right: 40px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .tenants-component {
    .section-costs {
      padding-bottom: 24px;

      .section-title {
        text-align: center;
      }
    }

    .table-services {
      margin-top: 16px;

      .title-table {
        margin-bottom: 16px;
      }

      .option {
        padding-left: 5px;

        > img {
          order: 1;
          padding-right: 0;
          margin-right: 14px;
        }

        p {
          order: 2;
          flex: 1;
          position: relative;
          padding-right: 20px;

          .info-btn {
            position: absolute;
            right: 0;
            top: 0;
          }
        }
      }
    }
  }
}