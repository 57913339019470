@import "../../variables";

.owners-component {}

.wrapper-section-hero-container {
  overflow: hidden;
  display: flex;
  height: 100%;
  &.page-description {
    @media (max-width: 767px) {
      height: auto;
    }
  }
}

.owners-component .wrapper-section-hero {

  padding-top: var(--milluu-header-end-h, 120px);

  background: white;

  display: flex;
  align-items: center;

  .wrapper-hero-description {
    max-width: 486px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    .owner-h1 {
      font-size: 20px;
      line-height: 49px;
      letter-spacing: -0.04em;
    }

    .owner-h2 {
      font-size: 48px;
      font-weight: 600;
      line-height: 49px;
      letter-spacing: -0.04em;
    }

    .owner-h3 {
      margin-top: 30px;
      margin-bottom: 30px;
      font-size: 18px;
      line-height: 22px;
    }

  }

  .wrapper-hero-img {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-top: 20px;

    @media (max-width: 575px){
        height: unset;
    }

    .mobile {
        display: none;
        @media (max-width: 575px){
            display: block;
        }
    }

    .not-mobile {
        display: block;
        @media (max-width: 575px){
            display: none;
        }
    }

    img {
        height: 100%;
        object-fit: cover;
        max-height: 700px;
    }
  }


  @media (min-width: 768px) and (max-width: 991px) {
    .wrapper-hero-description {
      .owner-h2 {
        font-size: 44px;
        line-height: 45px;
      }
    }
  }


  @media (max-width: 767px) {
    margin-top: 50px;

    .wrapper-hero-description {
      max-width: 100%;

      .owner-h1 {
        font-size: 18px;
        line-height: 37px;
        letter-spacing: -0.04em;
      }

      .owner-h2 {
        font-size: 36px;
        font-weight: 600;
        line-height: 37px;
        letter-spacing: -0.04em;
      }

      .owner-h3 {
        font-size: 18px;
        line-height: 22px;
      }
    }

    .wrapper-hero-img {
      height: 500px;
    }
  }
}

.owners-component .section-header {
  max-width: 1112px;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  word-wrap: pre-wrap;

  strong {
    font-weight: 600;
  }


  display: flex;
  flex-direction: column;

  .features-sub1 {
    text-align: center;

    @media (max-width: 991px) {
      br {
        display: none;
      }
    }
  }

  .features-sub2 {
    text-align: center;
    margin-top: 30px;
  }
}

.owners-component .wrapper-section-under-hero {
  background: #F1F4F6;
  padding-top: var(--section-vertical-space);
  padding-bottom: var(--section-vertical-space);

  @media (max-width: 767px){
    margin-top: 0px;
    }

  .section-features-pre-img {
    margin-top: var(--section-vertical-space-inner);
    height: 464px;
    position: relative;
    border-radius: 30px 30px 0 0;
    overflow: hidden;

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(241, 244, 246, 0) 44.05%, #F1F4F6 100%);
      top: 0;
      left: 0;
    }

    @media screen and (max-width: 767px) {
      height: 240px;
    }

    &>img {
      height: 100%;
      width: 100%;
      padding-bottom: 1px;
      object-fit: cover;
      object-position: center right;
    }
  }

  .section-features-pre {
    margin-top: calc(var(--section-vertical-space-inner) - 24px);

    .feature-pre-wrapper {
      border-radius: 30px;
      padding: 30px;
      flex: 1;

      h3 {
        font-size: 26px;
        font-weight: 600;
        letter-spacing: -0.04em;
        margin-bottom: 30px;
      }

      &.feature-pre-wrapper-info {
        background: white;
      }

      &.feature-pre-wrapper-list {
        background: $color-milluu-green;
        color: white;

        .unstyled-list {
          //padding-left: 35px;

          .unstyled-list-item {
            font-size: 14px;
            line-height: 24px;

            margin-bottom: 20px;

            &>.unstyled-list-item-icon {
              flex-shrink: 0;
              width: 35px;
              height: 35px;
              background-image: url("./img/icon-check-35.svg");
              margin-right: 20px;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }


      }

      @media screen and (min-width: 992px) {
        padding: 60px;
      }

      @media screen and (max-width: 767px) {
        h3 {
          font-size: 24px;
        }
      }
    }
  }
}

.owners-component .wrapper-section-features {
  background: #F1F4F6;

  .wrapper-section-features-features {
    margin-top: var(--section-vertical-space-inner);
  }
}


.info-icon-component {
  fill: #F1F4F6;
}

.owners-component .section-costs {
  background: #F1F4F6;
  padding-top: var(--section-vertical-space);
  padding-bottom: var(--section-vertical-space);
}

.owners-component .section-costs {


  @mixin active-row-column($col-num) {
    .row-head {
      .col-row {
        &:nth-child(#{$col-num}) {
          //box-shadow: 0 -3px 0 0 $color-milluu-violet, -3px 0 0 0 $color-milluu-violet, 3px 0 0 0 $color-milluu-violet;
          //border-bottom: none;
          //border-top-right-radius: 16px;
          //border-top-left-radius: 16px;

          .plan-header {
            //background-color: red;
          }
        }
      }
    }

    .row-table {
      .col-row {
        &:nth-child(#{$col-num}) {
          //box-shadow: -3px 0 0 0 $color-milluu-violet, 3px 0 0 0 $color-milluu-violet;
        }
      }
    }

    .row-footer {
      .col-row {
        &:nth-child(#{$col-num}) {
          //box-shadow: 3px 0 0 0 $color-milluu-violet, 0 3px 0 0 $color-milluu-violet, -3px 0 0 0 $color-milluu-violet;
         // border-bottom-right-radius: 16px;
          //border-bottom-left-radius: 16px;
        }
      }
    }

  }


  .table-services {
    position: relative;


    .row-table,
    .row-head,
    .row-footer {
      display: flex;
      flex-wrap: wrap;

      .col-row {
        flex: 1;
        display: flex;

        &:first-child {
          text-align: left;
          width: 45%;
          flex: initial;

          @media (min-width: 1200px) {
            width: 50%;;
          }
        }
      }
    }

    &.active-2 {
      @include active-row-column(2);
    }

    &.active-3 {
      @include active-row-column(3);
    }

    .row-head {
       align-items: stretch;
    }

    .row-table {
      &:nth-of-type(even) {
        //background: $gray-4;
      }
    }

    .row-footer {
      .plan-footer {
        background: white;
        height: 30px;
        border-radius: 0 0 30px 30px;
        margin: 0 12px;

        &.active {
          background: #003E46;
        }
      }
    }

    .info-btn {
      line-height: 0;
      margin-left: 8px;
      width: 16px;
      height: 16px;
      color: inherit;
      vertical-align: text-top;
    }

    .plan-header {
      background-color: white;
      border-radius: 30px 30px 0 0;
      margin: 0 12px;
      padding: 30px 10px 40px 10px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &.active {
          background: #003E46;
          color: white;
          .plan-fees {
            border-color: #1E5A62;
            &>div:nth-of-type(even) {
              background: #1E5A62;
            }
          }
          .info-icon-component {
            fill: none;
          }

      }

      .plan-title {
        font-size: 24px;
        font-weight: 600;
        padding-bottom: 15px;
        letter-spacing: -0.04em;
        text-align: center;
        br {
          display: none;
        }
      }

      .plan-fees {

        border-radius: 20px;
        border: 3px solid #F1F4F6;
        overflow: hidden;
        text-align: center;
        margin-bottom: 15px;
        font-size: 14px;
        letter-spacing: -0.04em;

        &>div {
          padding: 15px;
        }

        &>div:nth-of-type(even) {
          background: #F1F4F6;
        }

        .fee-title {
          text-transform: uppercase;
          font-weight: 600;
          margin-bottom: 15px;

        }

        .fee-description {
          margin-bottom: 15px;
          //color: black;
        }

        i {
          font-style: normal;
          color: $color-milluu-green;
        }

      }

      &.active {
        //background: #003E46;
      }

      .main-button {
        width: 100%;
        margin-top: auto;
      }
    }

    .plan-attribute-name {
      margin-right: 12px;
      border-bottom: 1px solid #272727;

      .option-title {
        padding: 10px 8px 10px 0px;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
      }
    }

    .plan-attribute-value {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 12px;
      background: white;
      position: relative;
      img { height: 25px;}

      &.active {
        background: #003E46;
        color: #F1F4F6;
        //border-bottom: 1px solid #F1F4F6;
        &::after {
          border-color: #F1F4F6;
        }

      }

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 10px;
        width: calc(100% - 20px);
        //height: 1px;
        border-bottom: 1px solid black;
      }
    }

    .cell-content {
      flex: 1;
      align-self: stretch;
    }

    .services-header-desktop {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;

      .section-title {
        margin-bottom: 30px;
      }

      .section-title-info {
        max-width: 418px;
      }

      .section-title-explore {
        margin-top: auto;
        margin-bottom: 40px;
      }

      @media (max-width: 991px) {
        display: none;
      }
    }

    @media (max-width: 991px) {

        .row-table,
          .row-head,
          .row-footer {

            .col-row {
              min-width: 10px;
              //width: 50%;
              &:first-child {
                width: 100%;
              }
            }
          }

          .plan-header {
            padding: 30px 10px 30px 10px;
            margin: 0;
          }

          .plan-attribute-name {
             margin-left: 0;
             padding-right: 16px;
             position: relative;
             border-bottom: 1px solid #F1F4F6;

            .option-title {
              padding: 5px 10px 5px 10px;
              font-weight: 700;
              font-size: 14px;
            }
          }

          .plan-attribute-value {
            margin: 0;
            min-height: 45px;
            &.active {
              background: #003E461A;
              color: $gray-3;
            }

            &::after {
              border: 0;
            }
          }

          .row-footer {
             .plan-footer  {
                height: 0;
             }
        }

    }

    @media (max-width: 767px) {
      .plan-header {
        .plan-title {
          font-size: 20px;
          line-height: 20px;
          br {
            display: unset;
          };
        }
      }
    }
  }


  .services-header-mobile {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    .section-title {
      margin-bottom: 50px;
    }

    .section-title-info {
      max-width: 100%;
      margin-bottom: 50px;
    }

    .section-title-explore {
      margin-top: auto;
      padding-bottom: 40px;
    }

    @media (min-width: 992px) {
      display: none;
    }
  }


  .section-title {
    font-weight: 600;
  }

  .section-title-info {
    font-size: 14px;
    line-height: 25px;

    @media (max-width: 992px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .section-title-explore {
    font-size: 24px;
    line-height: 40px;
    letter-spacing: -0.04em;
    font-weight: 600;
  }
}

.owners-component {
  .float-container {
    position: fixed;

    bottom: 23px;
    right: 35px;

    z-index: 6;
  }
}


.owners-component .wrapper-section-evaluate {
  background: #F0F4F5;
  //padding-top: var(--section-vertical-space);
  padding-bottom: var(--section-vertical-space);

  .section-evaluate-img {
    min-height: 415px;
    border-radius: 30px;
    overflow: hidden;
    height: 100%;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .section-evaluate-info {
    background: white;
    border-radius: 30px;
    padding: 60px;

    .section-evaluate-info-title {
      margin-bottom: 30px;
    }

    .section-evaluate-info-p1, .section-evaluate-info-p2  {
       font-size: 14px;
       line-height: 27px;
       color: #474747;
    }

    .main-button {
      margin-top: 30px;
    }
  }

  @media (max-width: 767px) {
    .section-evaluate-img {
      min-height: 264px;
    }
    .section-evaluate-info {
      padding: 30px;
      .main-button  {
        width: 100%;
      }
    }
  }

}
