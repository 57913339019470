@import "../../variables";

.tenants-services-section {
    /*
    ##Device = Desktops
    ##Screen = 1281px to higher resolution desktops
    */

    @media (min-width: 1281px) {}

    /*
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
    */

    @media (min-width: 1025px) and (max-width: 1280px) {}

    /*
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
    */

    @media (min-width: 768px) and (max-width: 1024px) {}

    /*
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
    */

    @media (min-width: 481px) and (max-width: 767px) {

        .mlimage {
            margin-left: 25px !important;
        }


    }

    /*
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
    */

    @media (min-width: 320px) and (max-width: 480px) {
        .mlimage {
            margin-left: 25px !important;
        }
    }


    @mixin active-row-column($col-num) {
        .row-head {
            .col-row {
                &:nth-child(#{$col-num}) {
                    //box-shadow: 0 -3px 0 0 $color-milluu-violet, -3px 0 0 0 $color-milluu-violet, 3px 0 0 0 $color-milluu-violet;
                    //border-bottom: none;
                    //border-top-right-radius: 16px;
                    //border-top-left-radius: 16px;

                    .plan-header {
                        //background-color: red;
                    }
                }
            }
        }

        .row-table {
            .col-row {
                &:nth-child(#{$col-num}) {
                    //box-shadow: -3px 0 0 0 $color-milluu-violet, 3px 0 0 0 $color-milluu-violet;
                }
            }
        }

        .row-footer {
            .col-row {
                &:nth-child(#{$col-num}) {
                    //box-shadow: 3px 0 0 0 $color-milluu-violet, 0 3px 0 0 $color-milluu-violet, -3px 0 0 0 $color-milluu-violet;
                    // border-bottom-right-radius: 16px;
                    //border-bottom-left-radius: 16px;
                }
            }
        }

    }


    .table-services {
        position: relative;


        .row-table,
        .row-head,
        .row-footer {
            display: flex;
            flex-wrap: wrap;

            .col-row {
                flex: 1;
                display: flex;

                &:first-child {
                    text-align: left;
                    width: 45%;
                    flex: initial;
                }
            }
        }

        &.active-2 {
            @include active-row-column(2);
        }

        &.active-3 {
            @include active-row-column(3);
        }

        .row-head {
            align-items: stretch;
        }

        .row-table {
            &:nth-of-type(even) {
                //background: $gray-4;
            }
        }

        .row-footer {
            .plan-footer {
                background: white;
                height: 30px;
                border-radius: 0 0 30px 30px;
                margin: 0 12px;

                &.active {
                    background: #003E46;
                }
            }
        }

        .info-btn {
            line-height: 0;
            margin-left: 8px;
            width: 16px;
            height: 16px;
            color: inherit;
            vertical-align: text-top;
        }

        .plan-header {
            background-color: white;
            border-radius: 30px 30px 0 0;
            margin: 0 12px;
            padding: 30px 10px 0px 10px;
            display: flex;
            flex-direction: column;
            align-items: center;

            &.active {
                background: #003E46;
                color: white;

                .plan-fees {
                    border-color: #1E5A62;

                    &>div:nth-of-type(even) {
                        background: #1E5A62;
                    }
                }

                .info-icon-component {
                    fill: none;
                }

            }

            .plan-title {
                font-size: 24px;
                font-weight: 600;
                padding-bottom: 15px;
                letter-spacing: -0.04em;
                text-align: center;

                br {
                    display: none;
                }
            }

            .plan-fees {

                border-radius: 20px;
                border: 3px solid #F1F4F6;
                overflow: hidden;
                text-align: center;
                margin-bottom: 15px;
                font-size: 14px;
                letter-spacing: -0.04em;

                &>div {
                    padding: 15px;
                }

                &>div:nth-of-type(even) {
                    background: #F1F4F6;
                }

                .fee-title {
                    text-transform: uppercase;
                    margin-bottom: 15px;
                    font-weight: 600;
                    opacity: 0.5;
                }

                .fee-description {
                    margin-bottom: 15px;
                    //color: black;
                    font-size: 16px;
                    font-weight: 600;
                    letter-spacing: -0.04em;
                }

                i {
                    font-style: normal;
                    color: $color-milluu-green;
                }

            }

            &.active {
                //background: #003E46;
            }

            .main-button {
                width: 100%;
                margin-top: auto;
            }
        }

        .plan-attribute-name {
            margin-right: 12px;
            border-bottom: 1px solid #272727;

            .option-title {
                padding: 10px 8px 10px 0px;
                font-size: 14px;
                font-weight: 700;
                line-height: 20px;
            }
        }

        .plan-attribute-value {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 12px;
            background: white;
            position: relative;

            img {
                height: 25px;
            }

            &.active {
                background: #003E46;
                color: #F1F4F6;

                //border-bottom: 1px solid #F1F4F6;
                &::after {
                    border-color: #F1F4F6;
                }

            }

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 10px;
                width: calc(100% - 20px);
                //height: 1px;
                border-bottom: 1px solid black;
            }
        }

        .cell-content {
            flex: 1;
            align-self: stretch;
        }

        .services-header-desktop {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;

            .section-title {
                margin-bottom: 30px;
            }

            .section-title-info {
                max-width: 418px;
            }

            .section-title-explore {
                margin-top: auto;
                margin-bottom: 40px;
            }

            @media (max-width: 575px) {
                display: none;
            }
        }

        @media (max-width: 575px) {

            .row-table,
            .row-head,
            .row-footer {

                .col-row {
                    min-width: 10px;

                    //width: 50%;
                    &:first-child {
                        width: 100%;
                    }
                }
            }

            .plan-header {
                padding: 30px 10px 30px 10px;
                margin: 0;
            }

            .plan-attribute-name {
                margin-left: 0;
                padding-right: 16px;
                position: relative;
                border-bottom: 1px solid #F1F4F6;

                .option-title {
                    padding: 5px 10px 5px 10px;
                    font-weight: 700;
                    font-size: 14px;
                }
            }

            .plan-attribute-value {
                margin: 0;
                min-height: 45px;

                &.active {
                    background: #003E461A;
                    color: $gray-3;
                }

                &::after {
                    border: 0;
                }
            }

            .row-footer {
                .plan-footer {
                    height: 0;
                }
            }

        }

        @media (max-width: 767px) {
            .plan-header {
                .plan-title {
                    font-size: 20px;
                    line-height: 20px;

                    br {
                        display: unset;
                    }

                    ;
                }
            }
        }
    }


    .services-header-mobile {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;

        .section-title {
            margin-bottom: 50px;
        }

        .section-title-info {
            max-width: 100%;
            margin-bottom: 50px;
        }

        .section-title-explore {
            margin-top: auto;
            padding-bottom: 40px;
        }

        @media (min-width: 992px) {
            display: none;
        }
    }


    .section-title {
        font-weight: 600;
    }

    .section-title-info {
        font-size: 14px;
        line-height: 25px;

        @media (max-width: 992px) {
            font-size: 16px;
            line-height: 20px;
        }
    }

    .section-title-explore {
        font-size: 24px;
        line-height: 40px;
        letter-spacing: -0.04em;
        font-weight: 600;
    }



    .float-container {
        position: fixed;

        bottom: 23px;
        right: 35px;

        z-index: 6;
    }

    .main-image {
        object-fit: cover;
        height: 100%;
        gap: 0px;
        border-radius: 30px;
        @media (max-width: 575px) {
            max-height: 300px;
        }
    }

    .image-before {
        @media (min-width: 992px) {
            display: none
        }
    }

    .image-after {
        @media (max-width: 992px) {
            display: none;
        }
    }

}

.tenants-services-section .section-costs {
    background: #F1F4F6;
    padding-top: var(--section-vertical-space);
    padding-bottom: var(--section-vertical-space);
}