@import "../../variables";

.secondary-lp {
  padding-bottom: 100px;

  .custom-hero {
    img {
      margin-bottom: 32px;
    }

    .description {
      padding-top: 9.5%;
    }
  }

  .section-costs {
    padding-bottom: 40px;
  }
}

@media (max-width: 991px) {
  .secondary-lp {
    padding-bottom: 80px;

    .custom-hero {
      .description {
        padding-top: 134px;
      }
    }
  }
}

@media (max-width: 767px) {
  .secondary-lp {
    padding-bottom: 48px;

    .custom-hero {
      .description {
        padding-top: 30px;
      }

      h1 {
        width: 225px;
      }
    }

    .wrapper-section-under-hero {
      margin-top: 30px;
    }

    .slider-wrapper {
      padding-bottom: 48px;
    }

    .section-costs {
      padding-bottom: 24px;
    }
  }
}