@import "../../variables";

.section-news {
    padding-bottom: 100px;
    padding-top: 100px;

    .section-title {
      display: flex;
      justify-content: center;
      padding-bottom: 50px;
    }

    .col-news {
      max-width: 400px;
      color: $color-text;
      padding-left: 24px;
      padding-right: 12px;

      img {
        width: 94px;
        height: 94px;
        //filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.1));
      }

      p {
        flex: 1;
        //padding-left: 24px;
        font-size: 14px;
        line-height: 24px;
      }
    }


    $icon-size: 92px;
    .sliderContainer {

      padding: 0 $icon-size 0 $icon-size;
      position: relative;

      &>.slider-buttons {
        position: absolute;
        top: calc(50% - 46px);
        width: $icon-size;
        height: $icon-size;
        background: white;
        border-radius: 100%;
        cursor: pointer;

        &:active {
          background: #F0F4F5;
        }
      }

      .slider-prev-button {
        left: 0;
      }

      .slider-next-button {
        right: 0;
      }
    }

    @media (min-width: 768px) and (max-width: 991px) {
      .col-news {
        max-width: 512px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    @media (max-width: 767px) {

      .col-news {
        align-items: center;
        text-align: center;
        max-width: 100%;
      }

      .sliderContainer {
        padding: 0 0 $icon-size 0;

        &>.slider-buttons {
          bottom: 0;
          top: unset;
        }

        .slider-prev-button {
          left: calc(50% - #{$icon-size} - 10px);
        }

        .slider-next-button {
          right: calc(50% - #{$icon-size} - 10px);
        }
      }

    }

    @media (min-width: 1200px) {
      .col-news {
        max-width: 512px;
        padding-left: 72px;
        padding-right: 0;
      }
    }

}