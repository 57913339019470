@import "../../variables";

.home-component {

  .section-img {
    background: white;
    overflow: hidden;
    position: relative;
    display: flex;
    padding-top: 75px;
    position: relative;
    overflow: hidden;

    @media (min-width: 992px) {
      display: none;
    }

    &>img {
      height: 580px;
      object-position: center top;
      position: relative;
      //left: -50px;
      object-fit: cover;
    }

    @media (max-width: 575px) {
      &>img {
        height: 400px;
      }
    }

  }


  .section-cta {
    background: #F0F4F5;

    padding-top: 100px;
    padding-bottom: 100px;

    .box-wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;

      .box-header {
         display: flex;

        .buttons-group {
          display: flex;
          justify-content: space-around;
          a>img {
            height: 48px;
          }
        }
      }

      .callToActionBtn {
        align-self: flex-start;
      }

      @media (max-width: 575px)  {
        .callToActionBtn {
          align-self: stretch;
        }
      }
    }


    .box-header {
      height: 50px;
      display: flex;
      align-items: start;

      &>img {
        height: 40px;
      }
    }

    .description {
      padding-top: 40px;
      padding-bottom: 40px;

      &>p.part1 {
        padding-top: 0;
        font-size: 18px;
        line-height: 28px;
      }

      &>p.part2 {
        font-size: 14px;
        line-height: 25px;
        padding-top: 22px;
      }

      .section-title {
        padding-bottom: 40px;
      }
    }

  }




  .float-container {
    position: fixed;

    bottom: 23px;
    right: 35px;

    z-index: 6;
  }


}

@media (max-width: 991px) {
  .home-component {

    .section-cta {
      background: white;
      padding-bottom: 0;

      .box-wrapper {
        border: 3px #F1F4F6 solid;
      }
    }


  }
}

@media (max-width: 767px) {
  .home-component {
    .section-news  {

      padding-top: 50px;

      .section-title {
        padding-bottom: 24px;
      }
    }
  }
}

@media (max-width: 575px) {
  .home-component {



  }
}