.contact-us-component {

    @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) and (orientation:portrait) {
        .modalCt {
            right: 0px !important;
            width: 259px !important;

        }
    }

    .showCt {
        display: block;
    }

    .hideCt {
        display: none !important;
    }


    .float {

        color: #FFF;
        border-radius: 50px;
        text-align: center;
        box-shadow: 2px 2px 3px #999;

        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        min-width: 202px;
        min-height: 48px;

        /* Colors/Milluu Green */
        background: #29B473;

        /* Shadow_Big */
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
        border-radius: 16px;
        margin-bottom: 8px;
        cursor: pointer;
    }




    .my-float {
        position: static;
        width: 139px;
        height: 24px;
        left: 48px;
        top: 16px;

        /* Body/Label */
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;

        /* identical to box height, or 171% */

        /* Shades/White */
        color: #FFFFFF;


        /* Inside Auto Layout */
        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 0px 25px;

    }

    .modalCt {
        display: none;
        position: fixed;
        bottom: 40px;
        right: 40px;
        padding: 16px;
        width: 320px;
        height: 197px;
        /* Shades/White */
        background: #FFFFFF;

        /* Colors/Milluu Green */
        border: 1px solid #29B473;
        box-sizing: border-box;
        box-shadow: 0px 8px 16px rgba(41, 180, 115, 0.2);
        border-radius: 16px;
        z-index: 8;
    }

    .modal_content {
        height: 176px;
    }

    .agent {
        //position: static;
        //width: 123px;
        //height: 24px;
        //left: 40px;
        //top: 4px;

        /* H4 */
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;

        /* identical to box height, or 150% */

        color: #000000;

        /* Inside Auto Layout */
        margin: 0px 8px;
    }

    .callbutton {
        /* Auto Layout */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 16px 8px 8px;
        margin-top: 12px;
        margin-bottom: 16px;

        position: static;
        width: 288px;
        height: 40px;
        left: 0px;
        top: 40px;

        background: rgba(41, 180, 115, 0.2);
        border-radius: 16px;

        /* Inside Auto Layout */
        flex: none;
        order: 1;
        flex-grow: 0;
    }

    .close {
        font-size: 2.3em;
        color: Black;
        float: right;
    }

    .close:hover {
        cursor: pointer;
    }
}