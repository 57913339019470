@import "../../variables";

:root {
  --milluu-header-end-h: 142px;
}


.header-component {
  position: absolute;
  width: 100%;
  top: 50px;
  z-index: 9;
  

  nav {
    background: #fff;
    box-shadow: $shadow-header;
    border-radius: 32px;
    padding: 16px  16px 16px 32px;
    //margin-top: 20px;
  }

  .navbar-brand {
    padding: 0;
    display: block;
  }

  .menu-items {
    margin-left: auto;
  }

  .links {
    display: flex;
    align-items: center;
    border-right: 1px solid $gray-1;
    height: 32px;
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
      border-right: none;
    }
  }

  .span-lng {
    text-transform: capitalize;
    font-size: 14px;
  }
  .span-country {
    text-transform: capitalize;
    font-size: 14px;
  }
  .language-icon {
    margin-right: 8px ;
  }

  .mr-16 {
    margin-right: 16px;
  }
  .ml-8 {
    margin-left: 8px;
  }

  .main-nav .navbar-nav {
    .link-nav {
      font-size: 14px;
      line-height: 24px;
      //color: $color-text;
      margin: 0 16px;
      padding: 0;
      text-decoration: none;
      position: relative;

      &:focus {
        color: $color-text;
      }

      // &.active {
      //   &::after {
      //     content: '';
      //     position: absolute;
      //     bottom: -8px;
      //     left: 0;
      //     right: 0;
      //     width: 100%;
      //     height: 2px;
      //     background-color: $color-milluu-orange;
      //   }
      // }
    }
  }

  .link-app {
    margin-right: 16px;
  }

 
  
  .dropdown-milluu-btn {
    background: transparent;
    border: none;
    border-radius: 0;
    padding: 0;
    display: flex;
    align-items: center;

    &:focus,
    &:hover {
      outline: none;
      box-shadow: none;
      background: transparent;
    }

    &::after {
      border: none;
      background: url("./img/icon-expand-more.svg");
      width: 14px;
      height: 8px;
      background-repeat: no-repeat;
      background-size: contain;
      vertical-align: middle;
      margin-left: 8px;
    }
  }

  .flag-wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    position: relative;
    border: 2px solid #333;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
    }
  }

  .lng-drop-down-toggle {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }

  .dropdown-menu-country {
    &.dropdown-menu[data-bs-popper] {
      left: -65px;
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
      top: 60px;

      @media screen and (max-width: 991px) {
        top: 51px;
      }
    }  
  }

  .dropdown-menu[data-bs-popper] {
    margin-top: 7px;
    padding: 25px;
    // width: 54px;
    left: -128px;
    top: 58px;
    min-width: auto;
    //border-color: $gray-1;
    border: none;
    box-shadow: 0px 0px 17.4px 0px #DBDEE0;
    border-radius: 0px 0px 32px 32px; 
    
    
    .dropdown-divider-language {
      border-color: #D9D9D9;
    }

    .dropdown-header-language {
      padding: 0px 0px 10px 0px;
    }

    .dropdown-item {
      padding: 5px 0px;

      &:hover {
        background-color: transparent;
        color: var(--bs-primary);
      }

      &.selected {
        //background-color: $gray-2;
      }
      &:active {
        color: var(--bs-primary);
        //background-color: $gray-4;
      }
    }
  }
}


@keyframes animation-border-round {
  0% {border-radius: 32px 32px 0px 0px;}
  //30% {border-radius: 32px 32px 0px 0px;}
  100% {border-radius: 32px !important;}
}

//@media (max-width: 991px) {
@media screen and (max-width: 991px) {
  :root {
    --milluu-header-end-h: 99.85px; //25px+ width 74.85px;
  }

  .header-component {
    top: 25px;
    nav {
      
      //filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.2));
      //box-shadow: none;
      padding: 16px;
      &.expanded {
        //border-radius: 32px 32px 0px 0px;
      }
      &.collapsed {
        //animation: animation-border-round 0.40s linear normal;
      }
    }
  
    .navbar-brand {
      width: 120px;
      margin-right: -16px;
    }

    .dropdown-menu[data-bs-popper] {
      top: 49px;
    }
   
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .header-component.header-component-ro {
    .milluu-navigation-links .link-nav {
      margin-left: 8px;
      margin-right: 8px;
      max-width: 70px;
      line-height: 1.2;
    }
  }
}

.header-component {
  .link-app-text {
    display: none;
  }
  .lng-select-text {
    display: none;
  }
 
}

@media (max-width: 767px) {
  .header-component {

    .main-nav {
      &.expanded {
        //border-bottom-left-radius: 0;
        //border-bottom-right-radius: 0;
      }
      &.collapsed {
        //animation: animation-border-round 0.35s ease-out normal;
      }
    }

    .navbar-toggler {
      border: none;
      padding: 0;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &.collapsed {
        .navbar-toggler-icon {
          background-image: url('./img/icon-menu.svg');
          width: 23px;
          height: 12px;
        }
      }

      .navbar-toggler-icon {
        background-image: url('./img/icon-close.svg');
        width: 23px;
        height: 16px;
        background-size: contain;
        background-position: center;
      }
    }

    .navbar-collapse {
      box-shadow: 0px 0px 17.4px 0px #DBDEE0;
      
      opacity: 1;
      width: 100%;
      background-color: #fff;
     
      border-bottom-left-radius: 32px;
      border-bottom-right-radius: 32px;
      margin: 16px -16px -16px -16px;

       &.collapsed.collapsing {
         height: 0;
         overflow: hidden;
         opacity: 0;
         transition: height 0.35s ease,  opacity 0.25s ease-in !important;
       }
    }

    .menu-items {
      margin: 9px 25px 9px 25px;
    }

    .links {
      flex-direction: column;
    }
    
    .links:first-child {
      border-top: none;
    }

    .links {
      justify-content: flex-start;
      align-items: flex-start;
      border-right: none;
      margin-right: 0;
      padding-top: 16px;
      padding-bottom: 16px;
      border-top: 1px solid $gray-1;
      height: auto;
    }

    .link-app {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding: 4px 0;

      .link-app-text {
        display: block;
        margin-left: 20px;
        color: #272727;
      }
    }

    .lng-select-text {
      display: block;
      color: #27272780;
    }
    
  
    .main-nav .navbar-nav {
      .link-nav {
        margin-bottom: 16px;
        margin-left: 0;
        margin-right: 0;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }


    .dropdown-milluu {
      width: 100%;
      .dropdown-header {
        display: none;
      }
      .dropdown-divider-language {
        display: none
      }
    };

    .dropdown-milluu-btn {
      width: 100%;
      display: flex;
      flex-direction: row;
      text-decoration: none;
      .lng-drop-down-toggle {
        display: flex;
        align-items: center;
        .lng-select-text {
          margin-right: 20px;
          text-decoration: none;
        }
      }
    }

    .dropdown-menu[data-bs-popper] {
      background-color: #F1F4F6;
      border-radius: 20px;
      border: 0px;
      padding: 10px;
      margin-top: 10px;
      box-shadow: none;
    }
  }
}