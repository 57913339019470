@import "../../variables";

.steps2-component {
  background: white;
  padding-top: var(--section-vertical-space, 100px);
  padding-bottom: var(--section-vertical-space, 100px);
}

.steps2-component .section-header {
  max-width: 1112px;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  word-wrap: pre-wrap;

  strong {
    font-weight: 600;
  }

  
  display: flex;
  flex-direction: column;

  .features-sub1 {
    text-align: center;

    @media (max-width: 991px) {
        br {
          display: none;
        }
    }
  }

  .features-sub2 {
    text-align: center;
    margin-top: 30px;
  }
}

.steps2-component .wrapper-section-features-features {
  margin-top: var(--section-vertical-space-inner);
}

.steps2-component {
  
  .step-container {
    padding-top: 36px ;
    position: relative;
    height: 100%;;
    display: flex;
    flex-direction: column;

  }

  .step-number {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    &>div {
      width: 72px;
      height: 72px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 20px;
      line-height: 20px;
      border-radius: 100%;
      background: #003E46;
      color: white;
    }
  }

  .step-box {
    min-height: 280px;
    padding: 60px 30px 30px 30px;
    border-radius: 30px;
    border: 3px solid #F1F4F6;
    flex: 1;
  }

  .step-title {
    font-size:26px;
    line-height: 30px;
    font-weight: 600;
    width: 100%;
    text-align: center;
    br {
      display: none;
    }
    strong {
      font-weight: 600;
    }
  }

  .step-description {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    margin-top: 30px;
    strong {
      font-weight: 400;;
    }

    @media screen and (min-width: 992px) {
      font-size: 14px;
    }
  }

  .steps-table {
    display: flex;
  }

  
}