@import "../../variables";

.main-button {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  
  border-radius: 16px;
  border: 1px solid $color-text;
  text-align: center;
  cursor: pointer;
  padding: 25px 35px 25px 35px;

  background-color: #fff;
  color: $color-text;
  
  &:hover {
    color: black;
  }
 
  &:active{
    color: black;
    background-color: var(--bs-gray-100);
    //border-color: var(--bs-btn-active-border-color);
  }
  

  &.with-shadow {
    box-shadow: $shadow-big-2;
  }
  
  &.orange {
    background-color: $color-milluu-orange;
  }

  &.green {
    background-color: $color-milluu-green;
    color: white;
    border: 0;
    &:active{
      background-color: #2db068;
      //border-color: var(--bs-btn-active-border-color);
    }
  }

  &.dark {
    background-color: #003E46;
    border: 0;
    color: white;

    &:active{
      background-color: rgb(1, 89, 100);
      //border-color: var(--bs-btn-active-border-color);
    }
  }
}