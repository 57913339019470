@import "../../variables";

.section-header {
    max-width: 1112px;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    word-wrap: pre-wrap;

    strong {
      font-weight: 600;
    }


    display: flex;
    flex-direction: column;

    .features-sub1 {
      text-align: center;

      @media (max-width: 991px) {
          br {
            display: none;
          }
      }
    }

    .features-sub2 {
      text-align: center;
      margin-top: 30px;
      font-size: 22px;
      line-height: normal;
    }

    .section-title {
        //min-height: 62px;
        text-align: center;
    }
}

.wrapper-section-features {
    padding-top: 100px;
    background: #FFFFFF;

    .wrapper-section-features-features {
        margin-top: var(--section-vertical-space-inner);
    }
}