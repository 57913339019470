@import "../../variables";

.contactV2-component {
    @media (max-width: 991px) {
        margin-top: 150px;
    }

    margin-top: 250px;
    margin-bottom: 100px;

    .normal-text {
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 400;
        letter-spacing: -0.04em;
        text-align: left;
    }

    .description-text {
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 400;
        text-align: left;
    }

    .description-margins {
        margin-top: 30px;
    }

    .big-text {
        font-family: Montserrat;
        font-size: 48px;
        font-weight: 600;
        letter-spacing: -0.04em;
        text-align: left;
    }

    .small-text {
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 400;
        text-align: left;
    }

    .contact-section {
        padding: 30px;
        gap: 30px;
        border-radius: 30px;
        border-style: solid;
        border-width: 3px;
        border-color: #F1F4F6;

        .title {
            font-family: Montserrat;
            font-size: 24px;
            font-weight: 600;
            letter-spacing: -0.04em;
            text-align: left;
        }

        .contact-icon {
            width: 25px;
            height: 25px;
        }
    }

    .send-section {
        // border-radius: 30px;
        // border-style: solid;
        // border-width: 3px;
        // border-color: #F1F4F6;

        .image {

            @media (max-width: 991px) {
                border-radius: 30px 30px 0px 0px;
            }

            border-radius: 30px 0px 0px 30px;
            border-style: solid;
            border-width: 1px;
            border-color: #F1F4F6;

            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        .send-form {
            @media (max-width: 991px) {
                border-radius: 0px 0px 30px 30px;
                border-width: 0px 3px 3px 3px;
            }

            border-radius: 0px 30px 30px 0px;
            border-style: solid;
            border-width: 3px 3px 3px 0px;
            border-color: #F1F4F6;
            padding: 15px 30px 15px 30px;
        }

        .label-text {
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 400;
            text-align: left;
        }

        .input-text {
            padding-left: 1rem;
            height: 60px;
            border-radius: 10px;
            border-style: solid;
            border-color: #272727;
            border-width: 1px 1px 1px 1px;
            width: 100%;
        }

        .input-textarea {
            padding-left: 1rem;
            padding-top: 1rem;
            height: 100px;
            border-radius: 10px;
            border-style: solid;
            border-color: #272727;
            border-width: 1px 1px 1px 1px;
            width: 100%;
        }

        .input-check {
            min-width: 30px;
            min-height: 30px;
            border-radius: 10px;
            border-style: solid;
            border-color: #272727;
            border-width: 1px 1px 1px 1px;
            align-self: start;
        }

        .submit-btn {
            padding: 20px;
            border-radius: 73px;
            background-color: #272727;
            color: white;
            width: 100%;
        }

        .mt-small {
            margin-top:6px;
        }

        .mt-separator {
            margin-top: 15px;
        }
    }

    .mt-regular {
        margin-top: 30px;
    }

    .mt-small {
        margin-top: 10px;
    }
}

.contact-component {
    margin-top: 250px;

  .contact-icon {
    background-color: #e82c2f;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    margin-right: 8px;
    color: white;
  }

  .contact-container {
    height: 100%;
    padding-top: 30px;
    padding-bottom: 15px;
  }

  .c-formular {
    background-color: whitesmoke;
    padding-top: 15px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 20px;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }

  .c-formular h1 {
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 900 !important;
    font-size: 34px;
    line-height: 41px;
  }

  .c-formular h1 {
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 900 !important;
    font-size: 34px;
    line-height: 41px;
    margin-bottom: 10px;
  }

  .c-info {
    padding-top: 30px;
  }


  .c-info h4 {
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 900 !important;
    font-size: 20px;
    line-height: 24px;
    padding-bottom: 10px;
  }

  ul.info-list {
    list-style-type: none;
    padding-left: 20px;
    margin-top: 20px;
  }

  ul.info-list li a {
    //color: #333333;
  }

  ul.info-list li {
    padding-bottom: 10px;
  }

  ul.info-list li img {
    padding-right: 5px;
  }

  .contact-container form input {
    display: block;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-top: 20px;
    width: 100%;
    border: none;
    border-bottom: 1px solid #999999;
    background-color: whitesmoke;
  }

  .contact-container form textarea {
    height: 130px;
    width: 100%;
    border: none;
    border-bottom: 1px solid #999999;
    padding-top: 20px;
    background-color: whitesmoke;
  }

}

.result-container {
    min-height: 20px;
    font-size: 13px;
    text-align: center;
}