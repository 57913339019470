@import "../../variables";
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/variables';

.what-is-milluu {

  background: linear-gradient(270deg, rgba(157, 180, 192, 0.15) 0%, rgba(255, 255, 255, 0.15) 100%);
  //margin-bottom: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  max-width: 100vw;
  overflow: hidden;;

  .buttons-group {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    a {
      &>img {
        height: 46px;
        @media (min-width: 768px) {
          height: 50px;
        }
      }
      &:first-child {margin-right: 20px;}
    }
  }

  @media (min-width: 992px) {
    background: white;
    padding-bottom: 0;

    .buttons-group {
      display: none;
    }
  }

  .what-is-milluu-container {
    //margin-top: 30px;
    background: white;
    border-radius: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-right: 30px;
    padding-left: 30px;

    @media (min-width: 992px) {
        padding-left: 0px;

      .section-title-wrapper {
        padding-top: 40px;
      }
      .unstyled-list {
        padding-top: 40px;
      }

      .img-container {
        position: relative;
        overflow: visible;
        height: calc(381px - 25px);
        img {
          height: 381px;
          max-width: unset;
          position: absolute;
          left: -100px;
          top: -25px;

        }
      }

    }

    @media (min-width: 1200px) {
      .img-container {
        img {
          left: -65px;
        }
      }
    }
  }


  ul {
    //margin-bottom: 30px;

    li {
      font-size: 14px;
      line-height: 24px;
      padding-left: 24px;
      margin-bottom: 24px;
      position: relative;

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 4px;
        width: 16px;
        height: 16px;
        background-image: url("./img/icon-check.svg");
      }
    }
  }


  .section-title-wrapper {
    margin-bottom: 32px;
  }

  .intro-text {
    font-size: 16px;
    line-height: 20px;
  }
}