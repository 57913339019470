@import "../../variables";

.partnersV2-component {
    --section-vertical-space: 100px;
    --section-vertical-space-inner: 50px;

    @media (max-width: 767px){
      --section-vertical-space: 50px;
    }

    background-color: #FFFFFF;
}

.partnersV2-component .wrapper-section-hero {
    padding-top: var(--milluu-header-end-h, 120px);

    background: transparent;


    .wrapper-hero-description {
        max-width: 486px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        @media (max-width: 767px){
        margin-top: 0px;
        }

        .tenant-h1 {
        font-size: 20px;
        line-height: 49px;
        letter-spacing: -0.04em;
        }

        .tenant-h2 {
        font-size: 48px;
        font-weight: 600;
        line-height: 49px;
        letter-spacing: -0.04em;
        }

        .tenant-h3 {
        margin-top: 30px;
        margin-bottom: 30px;
        font-size: 18px;
        line-height: 22px;
        }

    }

    .wrapper-hero-img {
        //padding-top: 50px;

        display: flex;
        align-items: end;
        height: 100%;
        position: relative;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        // &:after {
        //     content: "";
        //     position: absolute;
        //     width: 100%;
        //     height: 100%;
        //     background: linear-gradient(270deg, rgba(255, 255, 255, 0) 50%, #FFFFFF 100%),
        //                 linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 15.4%);
        //     top:0;
        //     left:0;
        // }

        &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background: linear-gradient(270deg, rgba(255, 255, 255, 0) 50%, #FFFFFF 100%),
                             linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 15.4%);
            top:0;
            left:0;
        }

        //,
    }

    @media (min-width: 768px) and (max-width: 991px) {
        .wrapper-hero-description {
        .tenant-h2 {
            font-size: 44px;
            line-height: 45px;
        }
        }
    }


    @media  (max-width: 767px) {
        margin-top: 30px;
        .wrapper-hero-description {
            max-width: 100%;
        .tenant-h1 {
            font-size: 18px;
            line-height: 37px;
            letter-spacing: -0.04em;
        }

        .tenant-h2 {
            font-size: 36px;
            font-weight: 600;
            line-height: 37px;
            letter-spacing: -0.04em;
        }

        .tenant-h3 {
            font-size: 18px;
            line-height: 22px;
        }
        }

        .wrapper-hero-img {
        padding-top: 0rem;
        //height: 500px;
        }
    }
}



.partners-component {
  .custom-hero {
    .description {
      padding-top: 24.5%;
    }

    h1 {
      padding-bottom: 0;
    }
  }

  .steps-section {
    .description {
      padding: 0 20px;
    }

    p {
      font-size: 14px;
      line-height: 20px;
      text-align: left;
    }
  }

  .section-costs {
    padding-bottom: 60px;

    .wrapper-costs {
      background-color: $color-milluu-violet;
      color: #fff;
      padding: 24px;
      border-radius: 16px;
    }

    .section-title {
      line-height: 56px;
      margin-bottom: 16px;
    }

    .description {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 16px;
    }

    .fee {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .content {
      flex: 1;
      max-width: 750px;
      padding-right: 20px;
    }

    .img {
      width: 180px;
    }
  }
}

@media (max-width: 1200px) {
  .partners-component {
    .steps-section p {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

@media (max-width: 991px) {
  .partners-component {
    .steps-section {
      .description {
        padding: 0 5px;
      }
    }

    .section-costs {
      .section-title {
        line-height: 32px;
      }

      .img {
        width: 120px;
      }
    }
  }
}
@media (max-width: 767px) {
  .partners-component {
    .custom-hero {
      .description {
        padding-top: 20%;
      }
    }

    .steps-section {
      p {
        padding-left: 0;
        padding-right: 0;
      }

      .description {
        padding: 0;
      }
    }

    .section-costs {
      padding-bottom: 24px;

      .img {
        width: 180px;
        margin-top: 24px;
      }
    }
  }
}