@import "variables";
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/variables';

@font-face {
  font-family: 'Montserrat';
  src: url('resources/fonts/Montserrat-SemiBold.woff2') format('woff2'),
  url('resources/fonts/Montserrat-SemiBold.woff') format('woff'),
  url('resources/fonts/Montserrat-SemiBold.ttf') format('ttf'),
  url('resources/fonts/Montserrat-SemiBold.svg#Montserrat-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('resources/fonts/Montserrat-Regular.woff2') format('woff2'),
  url('resources/fonts/Montserrat-Regular.woff') format('woff'),
  url('resources/fonts/Montserrat-Regular.ttf') format('ttf'),
  url('resources/fonts/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('resources/fonts/Montserrat-Bold.woff2') format('woff2'),
  url('resources/fonts/Montserrat-Bold.woff') format('woff'),
  url('resources/fonts/Montserrat-Bold.ttf') format('ttf'),
  url('resources/fonts/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}



html {
  scroll-behavior: auto !important;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: $color-text;
}

:root {
  --bs-gutter-x: 15px;
  //--milluu-header-end-h: 0px;
  --milluu-additional-x-padding: 0px;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: 400;
}

p {
  margin: 0;
}

a {
  text-decoration: navajowhite;
}

strong {
  font-weight: 700;
}

img {
  max-width: 100%;
}

.orange-text {
  color: $color-milluu-orange;
}

.green-text {
  color: $color-milluu-green;
}

.blue-text {
  color: $color-milluu-blue;
}

.red-text {
  color: $color-milluu-red;
}

.violet-text {
  color: $color-milluu-violet;
}

.yellow-text {
  color: $color-milluu-yellow;
}

.border-color-green {
  border-color: $color-milluu-green;
}

.border-color-orange {
  border-color: $color-milluu-orange;
}

.border-color-red {
  border-color: $color-milluu-red;
}

.border-color-yellow {
  border-color: $color-milluu-yellow;
}

.border-color-blue {
  border-color: $color-milluu-blue;
}

.border-color-violet {
  border-color: $color-milluu-violet;
}


.section-title {
  font-size: 28px;
  line-height: 38px;
  letter-spacing: -0.04em;

  @include media-breakpoint-up(md) {
    font-size: 36px;
    line-height: 41px;
  }
}

.obs, small {
  font-size: 11px;
  line-height: 14px;
}


.unstyled-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.unstyled-button {
  padding: 0;
  background: transparent;
  border-radius: 0;
  border: none;
  box-shadow: none;
  outline: none;
}

.cursor-pointer {
  cursor: pointer;
}

.box-wrapper {
  background: #fff;
  //box-shadow: $shadow-big-1;
  border-radius: 30px;
  padding: 30px;
  overflow: hidden;
}

.custom-modal {
  padding: 30px;

  .modal-dialog {
    max-width: 820px;
    margin: 0 auto;
  }

  .modal-content {
    display: block;
    border: none;
    border-radius: 30px;
    padding: 30px;
    box-shadow: $shadow-big-2;
    position: relative;
    overflow: auto;
  }

  .header-modal {
    padding: 0 0 30px;
    border: none;
  }

  .section-title {
    flex: 1;
  }

  h3 {
    margin-bottom: 24px;
  }

  .small-header {
    font-size: 18px;
    line-height: 24px;
  }

  .block-paragraph {
    display: block;
  }

  p {
    font-size: 14px;
    line-height: 24px;
  }

  .obs {
    font-size: 11px;
    line-height: 14px;
    margin-top: 4px;
  }

  .btn-close {
    padding: 0;
    margin: 0;
    width: 32px;
    height: 32px;
    opacity: 1;
    background: url("resources/img/icon-close.svg");
    -webkit-background-size: contain;
    background-size: contain;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

// .slick-arrow {
//   &::before {
//     content: '';
//     width: 13px;
//     height: 20px;
//     position: absolute;
//     left: 0;
//     right: 0;
//     top: 0;
//     bottom: 0;
//     font-family: initial;
//     -webkit-background-size: contain;
//     background-size: contain;
//     background-repeat: no-repeat;
//     background-position: center;
//   }
// }

.slick-prev {
  // left: -50px;

  // &::before {
  //   background-image: url('resources/img/icon-prev.svg');
  // }
}

.slick-next {
  //right: -50px;

  // &::before {
  //   background-image: url('resources/img/icon-next.svg');
  // }
}

.page-description {
  
  height: 827px;
  
  @media (max-width: 1200px) {
    height: 827px;
  }

  @media (max-width: 991px) {
    height: 827px;
  }

  @media (max-width: 767px) {
    height: 405px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .container {
    max-width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .container {
    max-width: 768px; //768 + padding
  }
}

@media (min-width: 1400px) {
  .container {
    //max-width: 1166px; //1136 + padding
    max-width: 1364px;
  }
}

@media (max-width: 1200px) {
  .slick-prev {
    //left: 0;
  }

  .slick-next {
    //right: 0;
  }

  .slick-slider {
    // padding-left: 40px;
    // padding-right: 40px;
  }
}


@media screen and (max-width: 767px){
  :root {
    --milluu-container-x-padding: 12px;
  }
  .container.container-page-aligned {
    padding-right: 24px;
    padding-left: 24px;
  }
}


.milluu-w-100 {
    width: 100%;
}

.milluu-w-sm-auto {
  @media screen and (min-width: 576px) {
    width: auto;
  }
}

.milluu-w-md-auto {
  @media screen and (min-width: 768px) {
    width: auto;
  }
}


:root {
  --section-vertical-space: 100px;
  --section-vertical-space-inner: 50px;

  @media (max-width: 767px){
    --section-vertical-space: 50px;
  }
}