.apple-btn {
    width: 49%;
    height: 47px;
}

.android-btn {
    width: 49%;
}

.activeInv {
    display: none;
}

.inactiveInv {
    display: none;
}

.existingInv {
    display: none;
}
.existing30minInv {
    display: none;
}