@import "../../variables";



.section-features {

  --section-features-gap-y: 24px;

  .box-border { 
    border: 3px solid #F1F4F6;
  }

  //padding-bottom: 48px;
  //margin-bottom: calc(var(--section-features-gap-y) * -1);

  .section-features-title {
    display: inline-block;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom-width: 2px;
    border-bottom-style: solid;
  }


  .col-feature {
    margin-bottom: var(--section-features-gap-y);
  }

  .box-wrapper {
    height: 100%;

    img {
      margin-bottom: 40px;
      height: 80px;
    }

    .section-title {
      font-size: 26px;
      line-height: 30px;
      margin-bottom: 40px;
      strong {
        font-weight: 600;
      }
    }

    p {
      font-size: 14px;
      line-height: 27px;
      color: #474747;
      strong {
        font-weight: 600;
      }
    }
  }
}

@media (max-width: 767px) {
  .section-features {
    .col-feature {
      //margin-bottom: 16px;

      &:last-child {
        //margin-bottom: 0;
      }
    }

    
  }
}